import React from 'react';
import { Layout, Seo } from '@components/core';

const NotFoundPage = () => (
	<Layout>
		<Seo title="404: Not found" />
		<h1>NOT FOUND</h1>
	</Layout>
);

export default NotFoundPage;
